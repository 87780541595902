const db = {
    recipes: [

      {
        id: 2,
        name: 'Salmon with Avocado',
        image: 'https://i.imgur.com/rFXySII.jpeg',
        url_name: 'salmon-with-avocado',
        // preparation: 'salmon-with-avocado.md'
      },
      
      {
        id: 3,
        name: 'Crème Brûlée',
        image: 'https://i.imgur.com/6fRnJ8T.png',
        url_name: 'creme-brulee',
      },

      {
        id: 4,
        name: 'Focaccia',
        image: 'https://i.imgur.com/zDgmhZy.png',
        url_name: 'focaccia',
      },

    ],
  };
  
  export default db;