import React from 'react';
import './FoodCard.css'; // Import the CSS for this component
import { Link } from 'react-router-dom';

function FoodCard({ image, name, url_name, ingredients, onClick }) {
  return (
    <div className="food-card" onClick={onClick}>
      <Link to={`/${url_name}`}>
        <img src={image} alt={name} className="food-card-image" />
      </Link>
      <h2 className="food-card-name">{name}</h2>
    </div>
  );
}

export default FoodCard;