import React from 'react';
import FoodGrid from '../FoodGrid/FoodGrid';
import bannerImage from '../../assets/banner.png';
import './Home.css';


const Home = () => {
  return (
    <div>
      <h1>Easy and <u>Delicious</u> recipes</h1>

      <div className='banner-image'>
          <img src={bannerImage} alt="Banner" className="responsive-banner"/>
      </div>

      <h1>Last Recipes</h1>
      <FoodGrid/>
    </div>
  );
}

export default Home;