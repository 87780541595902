import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo.png'; // Importing the logo

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };



    return (
        <header className="header">
            <button onClick={toggleMenu} className="menu-button">
                Menu
            </button>
            
            <Link to="/" className="logo">
                <img src={logo} alt="Logo" />
            </Link>
            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <button onClick={toggleMenu} className="close-button">
                    ✖
                </button>
                <ul className="nav-links">
                    <li><Link to="https://form.jotform.com/233173958288066" target="_blank" rel="noopener noreferrer">Submit Recipe</Link></li>
                    <li><Link to="https://form.jotform.com/233173029548054" target="_blank" rel="noopener noreferrer">Sign up</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;