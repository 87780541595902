import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import db from '../../db'; // Adjust the path as needed
import './FoodDetails.css';
import ReactMarkdown from 'react-markdown';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Confetti from 'react-dom-confetti';

function FoodDetails() {
  const [foodItem, setFoodItem] = useState(null);
  const { url_name } = useParams();
  const [markdown, setMarkdown] = useState('');
  const [marked, setMarked] = useState([]); // Initialize marked as an empty array
  const [allChecked, setAllChecked] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [listItemCount, setListItemCount] = useState(0);
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    const item = db.recipes.find(recipe => recipe.url_name === url_name);
    setFoodItem(item);
    if (item && typeof item.url_name === 'string') {
      import(`../../assets/preparations/${item.url_name}.md`)
        .then(module => fetch(module.default))
        .then(response => response.text())
        .then(text => {
          setMarkdown(text);
          setListItemCount((text.match(/\n- /g) || []).length)
          setMarked(Array(listItemCount).fill(false));
  
          // Extract images from markdown
          const regex = /!\[.*?\]\((.*?)\)/g;
          let match;
          const newImages = [];
          while ((match = regex.exec(text)) !== null) {
            newImages.push(match[1]);
          }
          setImages(newImages);
        });
    }
  }, [url_name]);

  // Update allChecked based on checkedCount
  useEffect(() => {
    setAllChecked(checkedCount === listItemCount);
  }, [checkedCount, listItemCount]);


  const toggleMarked = index => {
    const newMarked = [...marked];
    newMarked[index] = !newMarked[index];
    setMarked(newMarked);
  
    // Update the checkedCount
    if (newMarked[index]) {
      setCheckedCount(prevCount => prevCount + 1);
    } else {
      setCheckedCount(prevCount => prevCount - 1);
    }
  };
  

  let listItemIndex = 0;
  const config = {
    angle: 90,
    spread: "233",
    startVelocity: 40,
    elementCount: "200",
    dragFriction: "0.21",
    duration: "5080",
    stagger: "27",
    width: "11px",
    height: "12px",
    perspective: "515px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <div className="food-details">
      {foodItem ? (
        <>
        <div style={{ position: 'absolute', top: '80%', left: '50%' }}>
            <Confetti active={ allChecked } config={ config }/>
        </div>       

          <h1>{foodItem.name}</h1>

          <Carousel showThumbs={false} showStatus={false}>
          {images.map((src, index) => {
            const isYoutubeVideo = src.includes("youtube.com");
            return (
              <div key={index}>
                {isYoutubeVideo ? (
                  <iframe
                    width="560"
                    height="560"
                    src={`https://www.youtube.com/embed/${src.split("shorts/")[1]}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img src={src} alt="" />
                )}
              </div>
            );
          })}
        </Carousel>

          <ReactMarkdown 
            className="preparation" 
            children={markdown}
            components={{
                img: ({node, ...props}) => {
                  return null; // Don't render the images in the markdown
                },

                li: ({node, ...props}) => {
                let index;
                if (node.children[0].tagName === 'input') {
                    index = listItemIndex;
                } else {
                    index = listItemIndex++;
                }
                return (
                    <li>
                    {props.children}
                    <input 
                        type="checkbox" 
                        checked={marked[index]} 
                        onChange={() => toggleMarked(index)}
                    />
                    </li>
                );
                }
            }}
            />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default FoodDetails;