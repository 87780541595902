import React from 'react';
import './FoodGrid.css'; // Import the CSS for this component
import FoodCard from '../FoodCard/FoodCard'; // Import the FoodCard component
import db from '../../db';

  
function FoodGrid() {
  return (
    <div className="food-grid">
      {db.recipes.map((recipe) => (
        <div key={recipe.id}>
          <FoodCard 
          image={recipe.image} 
          name={recipe.name} 
          url_name={recipe.url_name}
          ingredients={recipe.ingredients}/>
        </div>
      ))}
    </div>
  );
}

export default FoodGrid;